import * as React from "react";
import { HeadFC, Link } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <h1 className="text-3xl font-bold underline">Home</h1>
      <ul>
        <li>
          <Link to="/post">投稿一覧</Link>
        </li>
        <li>
          <Link to="/category">カテゴリ一覧</Link>
        </li>
        <li>
          <Link to="/book">紹介書籍一覧</Link>
        </li>
        <li>
          <Link to="/author">著者一覧</Link>
        </li>
      </ul>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <Seo />;
